import React from 'react';
import Carousel from '../components/ImageSlider';
import '../Capabilities.css'; // Ensure this file contains necessary styles

const Capabilities = () => {
  return (

    
    <div className="Capabilities">


      
     <div className="carousel-container">
        <Carousel />
      </div>

      <p className="page-content">
        CENTER STREET TECHNOLOGIES COMBINES LEADING-EDGE SKILLS AND PROCESSES INTO AN{' '}
        <a 
          href="https://www.americamakes.us/projects/5509-001-advanced-manufacturing-confluence-amc/" 
          className="link" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          ADVANCED MANUFACTURING CONFLUENCE
        </a>{' '}
        (AMC) TO AGILELY COLLABORATE WITH CUSTOMERS ON INNOVATIVE SOLUTIONS FOR LARGE-SCALE, POLYMER-BASED, STRUCTURAL NEEDS. CENTER STREET UTILIZES A ROBUST DIGITAL THREAD ARCHITECTURE TO SIMULATE THE PART’S PERFORMANCE AND DRIVE PHYSICAL MANUFACTURING TO ENSURE DESIRED RESULTS ARE ACHIEVED.
      </p>
    </div>
  );
};

export default Capabilities;
