import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../ContactUs.css';
import bubblesImage from '/bubbles.png';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'First name should only contain letters')
    .max(25, 'First name should be at most 25 characters')
    .required('First name is required'),
  lastName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Last name should only contain letters')
    .max(25, 'Last name should be at most 25 characters')
    .required('Last name is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Please enter a valid email address'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number should be digits only')
    .min(10, 'Phone number should be at least 10 digits')
    .max(12, 'Phone number should be at most 12 digits')
    .nullable(),
  message: Yup.string()
    .max(4000, 'Message should be at most 4000 characters')
    .required('Message is required'),
});

const ContactUs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      setSubmitError(false);
      setSubmitSuccess(false);
     
      try {
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });
        
        if (response.ok) {
          setSubmitSuccess(true);
          resetForm();
          setTimeout(() => {
            setSubmitSuccess(false);
          }, 10000);
        } else {
          setSubmitError(true);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitError(true);
      } finally {
        setTimeout(() => {
          setIsSubmitting(false);
        }, 10000);
      }
    },
  });

  return (
    <div className="ContactUs">
      <div className="contact-info">
        <img src={bubblesImage} alt="Bubbles" className="bubbles-image" />
        <p>Phone: (330) - 550 - 8928</p>
        <p>Email: INFO@CENTERSTREETECH.COM </p>
        <p>Address: 1062 OHIO WORKS DRIVE, YOUNGSTOWN, OH, 44510
        </p>
      </div>
      <div className="contact-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.firstName && formik.errors.firstName ? 'error' : ''}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error-message">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.lastName && formik.errors.lastName ? 'error' : ''}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error-message">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.email && formik.errors.email ? 'error' : ''}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="error-message">{formik.errors.phone}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.message && formik.errors.message ? 'error' : ''}
            ></textarea>
            {formik.touched.message && formik.errors.message ? (
              <div className="error-message">{formik.errors.message}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
          {submitSuccess && <div className="success-message">Form submitted successfully!</div>}
          {submitError && <div className="error-message">An error occurred. Please try again.</div>}
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
