import React, { useRef, useState, useEffect } from 'react';
import WelcomeTo from '../components/WelcomeTo';

const Home = () => {
  const videoRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    const checkVideoStatus = () => {
      if (videoElement && videoElement.paused) {
        // Display message if the video is paused
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    };

    // Attempt to play the video on mount
    const ensurePlay = () => {
      if (videoElement && videoElement.paused) {
        videoElement.play();
      }
    };

    ensurePlay();

    // Set up an interval to check video status periodically
    const intervalId = setInterval(checkVideoStatus, 1000); // Check every second

    // Clean up interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="home-container">
      <video
        ref={videoRef}
        className="background-video"
        autoPlay
        muted
        loop
        webkit-playsinline="true"
        playsInline
        disablePictureInPicture
        controlsList="nodownload noplaybackrate"
      >
        <source src="/testvideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showMessage && (
        <div className="message">
          <p>Disable Low Power Mode and refresh the page for the best experience.</p>
        </div>
      )}
      <div className="content">
        <WelcomeTo />
      </div>
    </div>
  );
};

export default Home;
