import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slogan from '../components/Slogan';
import HamburgerMenu from '../components/HamburgerMenu';
import Title from './Title';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/Welcome';
  const isSpecialPage = 
    location.pathname === '/Capabilities' || 
    location.pathname === '/privacy-policy' || 
    location.pathname === '/legal' ||
    location.pathname === '/ContactUs' || 
    location.pathname === '/AboutUs'; 

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isFading, setIsFading] = useState(false);
  const [exploreFadeIn, setExploreFadeIn] = useState(false);

  useEffect(() => {
    if (isSpecialPage) {
      const handleScroll = () => {
        const header = document.querySelector('.header');
        if (header) {
          const scrollY = window.scrollY;
          setIsHeaderVisible(scrollY < 50);
        }
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      setIsHeaderVisible(true);
    }
  }, [isSpecialPage]);

  useEffect(() => {
    if (isHomePage) {
      setIsFading(true);

      const fadeInTimeout = setTimeout(() => {
        setIsFading(false);
      }, 3000); 

      const exploreTimeout = setTimeout(() => {
        setExploreFadeIn(true);
      }, 3200); 

      return () => {
        clearTimeout(fadeInTimeout);
        clearTimeout(exploreTimeout);
      };
    } else {
      setExploreFadeIn(false);
    }
  }, [isHomePage]);

  const handleLogoClick = () => {
    if (!isHomePage) {
      setIsFading(true);
      setTimeout(() => {
        navigate('/Welcome');
        setIsFading(false);
      }, 300);
    }
  };

  return (
    <>
      <header className={`header ${isHomePage ? 'home-header' : ''} ${((isSpecialPage && !isHeaderVisible) ? 'hidden' : '')}`}>
        <img
          className={`logo ${isHomePage ? 'home-logo' : ''} ${isFading ? 'fade-out' : ''}`}
          src='/Logo.png'
          alt="Logo"
          onClick={handleLogoClick}
        />
        <Title isHomePage={isHomePage} />
        {isHomePage && <Slogan />}
        <nav className="navigation">
          {!isHomePage && (
            <>
              <li>
                <Link
                  to="/Capabilities"
                  className={location.pathname === '/Capabilities' ? 'active' : ''}
                >
                  Capabilities
                </Link>
              </li>
              <li>
                <Link
                  to="/AboutUs"
                  className={location.pathname === '/AboutUs' ? 'active' : ''}
                >
                  Our Story
                </Link>
              </li>
              <li>
                <Link
                  to="/ContactUs"
                  className={location.pathname === '/ContactUs' ? 'active' : ''}
                >
                  Contact
                </Link>
              </li>
            </>
          )}
        </nav>
      </header>
      {isHomePage && (
        <footer className={`ExploreCapabilities ${exploreFadeIn ? 'fade-in' : 'hidden'}`}>
          <Link to="/Capabilities">EXPLORE CAPABILITIES</Link>
        </footer>
      )}
      {isSpecialPage && <HamburgerMenu />} {/* Ensure HamburgerMenu is shown on special pages */}
    </>
  );
};

export default Header;
