import React from 'react';
import '../AboutUs.css';
import Carousel from '../components/Carousel';
import ScrollToTopButton2 from '../components/STTB2.jsx';

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="text-content">
        <h1 className="title">CENTER STREET TECHNOLOGIES</h1>
      { /* <p className="slogan2">BRIDGING ADVANCED DESIGN TO MANUFACTURING</p> */}
        <p className="description">
          CENTER STREET TECHNOLOGIES DRAWS ITS NAME FROM ONE OF YOUNGSTOWN, OHIO'S MOST ICONIC LANDMARKS: THE CENTER STREET BRIDGE.
          THIS HISTORIC BRIDGE WAS RENOWNED FOR BEING THE ONLY PLACE IN THE U.S. WHERE FIVE MAJOR RAILROADS INTERSECTED, MAKING IT THE
          BUSIEST MANUAL RAIL LINE CROSSING IN THE WORLD. AT ITS PEAK, MORE RAILROAD CARS PASSED UNDER THE CENTER STREET BRIDGE IN A
          SINGLE DAY THAN AT ANY OTHER LOCATION IN THE COUNTRY. 
        </p>
        <p className="description">
          INSPIRED BY THE BRIDGE’S LEGACY OF CONNECTION AND INNOVATION, CENTER STREET TECHNOLOGIES EMBODIES THE SAME SPIRIT. FOUNDED WITH
          THE VISION OF UNITING RESOURCES FROM ACROSS THE COUNTRY IN YOUNGSTOWN, OHIO, OUR ORGANIZATION REFLECTS THE GROUNDBREAKING NATURE
          OF THE BRIDGE ITSELF. OUR TEAM IS COMPRISED OF INDIVIDUALS FROM DIVERSE BACKGROUNDS AND INDUSTRIES, BRINGING TOGETHER A WEALTH OF
          KNOWLEDGE, EXPERIENCE, AND TALENT.
        </p>
        <p className="description">
          JUST AS THE CENTER STREET BRIDGE ONCE FACILITATED THE CONVERGENCE OF MAJOR RAILROADS, CENTER STREET TECHNOLOGIES NOW CONNECTS
          INDUSTRY-LEADING EXPERTS, CUTTING-EDGE TECHNOLOGY, AND PREMIER ORGANIZATIONS. OUR MISSION IS TO CREATE A REVOLUTIONARY IMPACT
          BY HARNESSING THESE DIVERSE RESOURCES AND TALENTS RIGHT HERE IN YOUNGSTOWN.
        </p>
      </div>
      <img className="background-image" src="/invriver.png" alt="ABOUT US" title="The Mahoning River" />
      <Carousel />
      
    </div>
  );
};

export default AboutUs;
