import React, { useEffect } from 'react';

const DisableAutoScroll = () => {
  useEffect(() => {
    const handleMiddleMouseClick = (event) => {
      if (event.button === 1) {
        event.preventDefault(); 
      }
    };

    const handleWheel = (event) => {
      if (event.deltaY !== 0) {
        event.stopPropagation();
      }
    };

    window.addEventListener('mousedown', handleMiddleMouseClick, { passive: false });
    window.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener('mousedown', handleMiddleMouseClick);
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return null;
};

export default DisableAutoScroll;
