import React from 'react';
import '../PrivacyPolicy.css'; 
import ScrollToTopButton2 from '../components/STTB2';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1 className="page-title">Privacy Policy</h1>
      <p className="privacy-content">
        Center Street Technologies ("we," "our," or "us") is committed to protecting the privacy of individuals who visit our website at <a href="http://www.centerstreettech.com" target="_blank" rel="noopener noreferrer">www.centerstreettech.com</a> (the “Site”). This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our Site. By using the Site, you agree to the practices described in this Privacy Policy.
      </p>
      <div className="privacy-content">
        <strong>1. Information We Collect</strong>
        <br />
        <strong>1.1 Information You Provide</strong>
        <br />
        When you register for an account, contact us through the "Contact" page, or interact with us in other ways, we may collect the following information:
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Any other information you provide voluntarily</li>
        </ul>
        <strong>1.2 Information Collected Automatically</strong>
        <br />
        When you use our Site, we may collect certain information automatically, including:
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Device identifiers and characteristics</li>
          <li>Operating system version</li>
          <li>Language preferences</li>
          <li>Referring URLs</li>
          <li>Information about your use of the Site</li>
        </ul>
        We use cookies, web beacons, and similar technologies to collect this information. Cookies are small data files placed on your device. You can manage cookie preferences through your browser settings. For more details on cookies and how to manage them, visit <a href="http://www.allaboutcookies.org/manage-cookies/index.html" target="_blank" rel="noopener noreferrer">AllAboutCookies.org</a>.
        We may use analytics tools such as Google Analytics to analyze usage patterns. For more information on Google Analytics and to opt-out, visit <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">Google’s Privacy Policy</a> and download the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-Out Browser Add-On</a>.
      </div>
      <div className="privacy-content">
        <strong>2. How We Use Your Information</strong>
        <br />
        We use the information we collect to:
        <ul>
          <li>Operate, maintain, and improve our Site and services</li>
          <li>Provide and deliver the services and information you request</li>
          <li>Respond to your inquiries and provide customer support</li>
          <li>Analyze usage trends to enhance our Site and develop new features</li>
          <li>Send administrative, informational, and marketing communications</li>
          <li>Ensure compliance with legal obligations and prevent fraud</li>
          <li>Enforce our Terms of Service and other legal rights</li>
        </ul>
      </div>
      <div className="privacy-content">
        <strong>3. How We Share Your Information</strong>
        <br />
        We may share your information with third parties under the following circumstances:
        <ul>
          <li>With third-party service providers who assist with website hosting, data analysis, customer service, email delivery, and other functions.</li>
          <li>In connection with mergers, acquisitions, reorganization, or other business transactions, including bankruptcy.</li>
          <li>When required to comply with laws, regulations, legal processes, or government requests.</li>
          <li>With aggregate information that does not identify or otherwise relate to an individual for any purpose.</li>
        </ul>
      </div>
      <div className="privacy-content">
        <strong>4. Third-Party Sites</strong>
        <br />
        Our Site may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. We encourage you to review the privacy policies of any third-party sites you visit.
      </div>
      <div className="privacy-content">
        <strong>5. Security</strong>
        <br />
        We implement reasonable physical, administrative, and technical safeguards to protect your information. However, no security measure is completely foolproof, and we cannot guarantee the absolute security of your data.
      </div>
      <div className="privacy-content">
        <strong>6. Your Choices</strong>
        <br />
        You can opt-out of receiving marketing emails from us by clicking the “unsubscribe” link included in each email. You may also contact us directly to request changes to your preferences.
      </div>
      <div className="privacy-content">
        <strong>7. Use of Site by Minors</strong>
        <br />
        Our Site is not intended for individuals under the age of 18. We do not knowingly collect personal information from minors. If you are under 18, please do not provide any personal information through our Site.
      </div>
      <div className="privacy-content">
        <strong>8. International Data Transfers</strong>
        <br />
        Our servers are located in the United States. By using our Site, you consent to the transfer of your information to and from countries outside of your country of residence, including the United States, which may have different data protection laws.
      </div>
      <div className="privacy-content">
        <strong>9. Updates to This Privacy Policy</strong>
        <br />
        We may update this Privacy Policy periodically. The “Effective Date” at the top of this page indicates when the policy was last revised. We will notify you of any significant changes through the Site or via email. We encourage you to review this Privacy Policy regularly to stay informed about our privacy practices.
      </div>
      <div className="privacy-content">
        <strong>10. Contact Us</strong>
        <br />
        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
        <br />
        <strong>Email:</strong> <a href="mailto:info@centerstreettech.com">info@centerstreettech.com</a>
        <br />
        <strong>Mailing Address:</strong>
        <br />
        Center Street Technologies
        <br />
        1062 Ohio Works Drive
        <br />
        Youngstown, Ohio
        <br />
        <a href="http://www.centerstreettech.com">centerstreettech.com</a>
      </div>
      <ScrollToTopButton2 />
    </div>
  );
};

export default PrivacyPolicy;
