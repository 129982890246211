import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import hamburgerIcon from '/hamburgerhover.png';
import closeIcon from '/X.png'; // Import the close icon
import '../HamburgerMenu.css';

const HamburgerMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const location = useLocation(); 

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // Hide header if scrolled more than 50px
      setIsHeaderHidden(scrollTop > 50);

      // Close the menu if scrolled to the top
      if (scrollTop === 0) {
        setIsMenuVisible(false); // Close the menu when at the top
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check on component mount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuVisible(false); // Close the menu when the route changes
  }, [location]);

  const handleMenuItemClick = () => {
    setIsMenuVisible(false); // Close the menu when a menu item is clicked
  };

  return (
    <div className="hamburger-container">
      <img
        className={`hamburger-icon ${isHeaderHidden ? 'visible' : ''}`}
        src={isMenuVisible ? closeIcon : hamburgerIcon}
        alt="Menu"
        onClick={() => setIsMenuVisible(!isMenuVisible)} // Toggle menu visibility
      />
      <div className={`hamburger-menu ${isMenuVisible ? 'visible' : ''}`}>
        <ul>
          <li><Link to="/Welcome" onClick={handleMenuItemClick}>Home</Link></li>
          <li><Link to="/Capabilities" onClick={handleMenuItemClick}>Capabilities</Link></li>
          <li><Link to="/ContactUs" onClick={handleMenuItemClick}>Contact</Link></li>
          <li><Link to="/AboutUs" onClick={handleMenuItemClick}>Our Story</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default HamburgerMenu;
