import React from 'react';
import '../Linked.css'; 
import linkedIcon from '/Linkedin.png';

const Linked = () => {
  const location = window.location.pathname;

  if (location === '/Welcome') return null;

  return (
    <div className="linked-icon">
      <a
        href="https://www.linkedin.com/company/center-street-technologies"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn Profile"
      >
        <img src={linkedIcon} alt="LinkedIn Icon" className="linked-image" />
      </a>
    </div>
  );
};

export default Linked;


/* see INSTAGRAM.JSX 

import React from 'react';
import '../Linked.css'; 
import linkedIcon from '/public/Linkedin.png';

const Linked = () => {
  const location = window.location.pathname;

  if (location === '/Welcome') return null;

  const handleLinkClick = (e) => {
   e.preventDefault();
    const userConfirmed = window.confirm("Do you want to be taken to the LinkedIn page?");
    if (userConfirmed) {
      window.open("https://www.linkedin.com/company/center-street-technologies", "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="linked-icon">
      <a
        href="https://www.linkedin.com/company/center-street-technologies"
        onClick={handleLinkClick}
        aria-label="LinkedIn Profile"
      >
        <img src={linkedIcon} alt="LinkedIn Icon" className="linked-image" />
      </a>
    </div>
  );
};

export default Linked;
*/ 