import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary'; 
import './index.css';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://e516eb28c81d6ea4a790288bb90aa6ce@o4507730803884032.ingest.us.sentry.io/4507730808274944",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

});

ReactDOM.createRoot(document.getElementById('root')).render(
  
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  
);
