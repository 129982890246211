import React, { useState, useEffect } from 'react';
import '../Home.css';

const Slogan = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000); 
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`slogan ${isVisible ? 'show' : ''}`}>
      BRIDGING ADVANCED DESIGN TO MANUFACTURING
    </div>
  );
};

export default Slogan;
