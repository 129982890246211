import React from 'react';
import '../Email.css'; 

const Email = () => {
  const location = window.location.pathname;
  if (location === '/Welcome') return null;

  return (
    <div className="email-icon">
      <a href="mailto:info@centerstreettech.com">
        <img src="/Email.png" alt="Email" className="email-image" />
      </a>
    </div>
  );
};

export default Email;
