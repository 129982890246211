import React from 'react';
import '../Legal.css';
import ScrollToTopButton2 from '../components/STTB2.jsx';

const Legal = () => {
  return (
    <div className="legal-page">
      <h1 className="legal-title">Terms of Service</h1>
      <p className="legal-content">
        Welcome to Center Street Technologies. By accessing or using our website at <a href="http://www.centerstreettech.com" target="_blank" rel="noopener noreferrer">www.centerstreettech.com</a> (the “Site”), you acknowledge that you have read, understood, and agree to be bound by the following terms and conditions (the “Terms of Service”). If you do not agree to these Terms, you must not use the Site.
      </p>
      <p className="legal-content">
        <strong>1. Use of Site</strong>
        <br />
        You are granted a limited, non-exclusive, non-transferable license to access and use the Site and its content solely for your personal, non-commercial purposes. You agree to comply with all applicable laws and regulations. You may not use the Site for any unlawful purpose or in a manner that could damage, disable, overburden, or impair the Site, or interfere with any other party's use of the Site. You must also comply with any additional rules or guidelines provided on the Site.
      </p>
      <p className="legal-content">
        <strong>2. User Content</strong>
        <br />
        You are solely responsible for any content that you post or submit to the Site. By posting content, you represent and warrant that you have the rights to such content and grant us a perpetual, worldwide, royalty-free license to use, reproduce, modify, and distribute such content. We reserve the right to remove any content that we deem inappropriate or in violation of these Terms.
      </p>
      <p className="legal-content">
        <strong>3. Intellectual Property</strong>
        <br />
        All content on the Site, including text, graphics, logos, and software, is the property of Center Street Technologies or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content on the Site without our prior written permission. Requests for permission should be directed to <a href="mailto:info@centerstreettech.com">info@centerstreettech.com</a>.
      </p>
      <p className="legal-content">
        <strong>4. Disclaimer of Warranties</strong>
        <br />
        The Site is provided “as is” and “as available” without any warranties of any kind, either express or implied. We do not warrant that the Site will be error-free, uninterrupted, or free of viruses or other harmful components. Your use of the Site is at your own risk.
      </p>
      <p className="legal-content">
        <strong>5. Limitation of Liability</strong>
        <br />
        In no event shall Center Street Technologies be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site, whether based on warranty, contract, tort, or any other legal theory. This limitation of liability does not apply to the extent that such liability cannot be excluded by law.
      </p>
      <p className="legal-content">
        <strong>6. Indemnification</strong>
        <br />
        You agree to indemnify, defend, and hold harmless Center Street Technologies and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in any way connected with your use of the Site or any violation of these Terms of Service.
      </p>
      <p className="legal-content">
        <strong>7. Changes to Terms</strong>
        <br />
        We may update these Terms of Service from time to time. The “Last Updated” date at the top of this page indicates when these Terms were last revised. We will notify you of any significant changes by posting the new Terms on the Site. Your continued use of the Site following such changes constitutes your acceptance of the revised Terms.
      </p>
      <p className="legal-content">
        <strong>8. Governing Law</strong>
        <br />
        These Terms of Service are governed by and construed in accordance with the laws of the State of Ohio, without regard to its conflict of law principles. Any disputes arising out of or related to these Terms or your use of the Site will be subject to the exclusive jurisdiction of the state or federal courts located in Ohio.
      </p>
      <p className="legal-content">
        <strong>9. Contact Us</strong>
        <br />
        If you have any questions about these Terms of Service, please contact us at:
        <br />
        <a href="mailto:info@centerstreettech.com">info@centerstreettech.com</a>
        <br />
        Center Street Technologies
        <br />
        Youngstown, Ohio
        <br />
        <a href="http://www.centerstreettech.com">www.centerstreettech.com</a>
      </p>
      <ScrollToTopButton2/>
    </div>
  );
};

export default Legal;
