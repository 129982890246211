import React, { useState, useEffect } from 'react';

const Title = ({ isHomePage }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isHomePage) {
      
      setIsVisible(false); 
      const fadeInTimeout = setTimeout(() => {
        setIsVisible(true);
      }, 1000); 

      return () => clearTimeout(fadeInTimeout); 
    } else {

      setIsVisible(false);
    }
  }, [isHomePage]);

  return (
    <div className="title-container">
      <h1 className={`title ${isHomePage ? (isVisible ? 'fade-in' : 'hidden') : 'static'}`}>
        CENTER STREET TECHNOLOGIES
      </h1>
    </div>
  );
};

export default Title;
