import React, { useState } from 'react';
import '../404page.css';

const NotFoundPage = () => {
  const [animationTriggered, setAnimationTriggered] = useState(false);

  const handleAnimation = () => {
    setAnimationTriggered(true);
    setTimeout(() => {
      setAnimationTriggered(false);
    }, 6000); 
  };

  return (
    <div className="not-found-container">
      <video className="background-video" autoPlay muted loop>
        <source src="/#.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <h1
          className={`not-found-text ${animationTriggered ? 'animate' : ''}`}
          onClick={handleAnimation}
        >
          404
        </h1>
        <br />
        <p className={`rainbow-wavy-text ${animationTriggered ? 'animate' : ''}`}>Oops! The page you are looking for does not exist.</p>
        <br />
        <p className={`rainbow-wavy-text ${animationTriggered ? 'animate' : ''}`}>Click the logo at the top of the page or any of the buttons to redirect.</p>
      </div>
      {animationTriggered && (
        <div className="logo-container">
          <img src="/BGRainbow.png" alt="Logo" className="exploded-logo" />
        </div>
      )}
    </div>
  );
};

export default NotFoundPage;
