import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import '../Carousel.css';

const partners = [
  { name: 'Partner 1', image: '/partner1.png' },
  { name: 'Partner 2', image: '/partner2.png' },
  { name: 'Partner 3', image: '/partner3.png' },
  { name: 'Partner 4', image: '/partner4.png', className: 'custom-carousel-image--small' },
  { name: 'Partner 5', image: '/partner5.png' },
  { name: 'Partner 6', image: '/partner6.png' },
  { name: 'Partner 7', image: '/partner7.png' },
  //{ name: 'Partner 8', image: '/partner8.png', className: 'custom-carousel-image--small' },
  { name: 'Partner 9', image: '/partner9.png' },
  { name: 'Partner 10', image: '/partner10.png' },
  //  name: 'Partner 11', image: '/partner11.png' },
  // { name: 'Partner 12', image: '/partner12.png' },
];

const Carousel = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = () => resolve(); // Resolve on error to avoid blocking
      });
    };

    Promise.all(partners.map(partner => loadImage(partner.image)))
      .then(() => {
        setImagesLoaded(true);
      });

    // Handle Safari reloads more gracefully
    window.onpageshow = (event) => {
      if (event.persisted) {
        setImagesLoaded(false);
        Promise.all(partners.map(partner => loadImage(partner.image)))
          .then(() => {
            setImagesLoaded(true);
          });
      }
    };
  }, []);

  if (!imagesLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="custom-carousel-container">
      <header className="custom-carousel-header">
        <h2>OUR PARTNERS</h2>
        <p>We work with some of the most influential companies in the field. Meet our partners.</p>
      </header>
      <Marquee 
        className="custom-carousel-marquee"
        speed={75}
        gradient={false} // Disable gradient
        pauseOnHover={false}
      >
        {partners.concat(partners).map((partner, index) => (
          <div key={index} className="custom-carousel-item">
            <img src={partner.image} alt={partner.name} className={`custom-carousel-image ${partner.className || ''}`} />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Carousel;
