import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Capabilities from './pages/Capabilities';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Header from './components/Header';
import Footer from './components/Footer';
import Linked from './components/Linked';
import Phone from './components/Phone'; 
import Email from './components/Email.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import Legal from './pages/Legal.jsx';
import ScrollToTop from './components/ScrollToTop';
import DisableAutoScroll from './components/DisableScrolling.jsx';
import NotFoundPage from './pages/404page.jsx'; 
import CaseInsensitiveRedirect from './components/CaseInsensitiveRedirect';
import AboutUs from './pages/AboutUs.jsx';
import './index.css';
import './App.css';
import './ContactUs.css';
import './Capabilities.css';
import './ExploreCapabilities.css';
import './Footer.css';
import './Header.css';
import './WelcomeTo.css';
import './Slogan.css';
import './Home.css';
import './logo.css';
import './Title.css';

const AppContent = () => {
  const location = useLocation();
  const hideFooterPaths = ['/Welcome'];

  return (
    <>
      <Header />
      <main>
        <CaseInsensitiveRedirect />
        <Routes>
          
          <Route path="/" element={<Navigate to="/Welcome" />} />
          <Route path="/Welcome" element={<Home />} />
          <Route path="/Capabilities" element={<Capabilities />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </main>
      {!hideFooterPaths.includes(location.pathname) && <Footer />}
      <Linked />
      <ScrollToTop />
      <DisableAutoScroll /> 
      <Email/>
      <Phone />
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
