import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

const normalizePath = (path) => {
  // Define the standardized path for each route
  const paths = {
    '/contactus': '/ContactUs',
    '/capabilities': '/Capabilities',
    '/welcome': '/Welcome',
    '/Aboutus' :'/AboutUs',
    '/aboutus' :'/AboutUs',
    '/join-the-team': '/ContactUs',
    '/contact-us': '/ContactUs',
    '/machine-overview': '/Welcome',
    // Add other paths if necessary
  };

  return paths[path.toLowerCase()] || path;
};

const CaseInsensitiveRedirect = () => {
  const location = useLocation();
  const normalizedPath = normalizePath(location.pathname);

  return normalizedPath !== location.pathname ? <Navigate to={normalizedPath} /> : null;
};

export default CaseInsensitiveRedirect;
