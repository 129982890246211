import React, { useState, useEffect } from 'react';

const WelcomeTo = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`welcome-to ${isVisible ? 'show' : ''}`}>
      <h2>WELCOME TO</h2>
    </div>
  );
};

export default WelcomeTo;
